import { useAuth } from 'react-oidc-context'
import { IdTokenClaims } from 'oidc-client-ts'
import { jwtDecode } from 'jwt-decode'

interface OrganisationClaims {
  organisation_ids: string[]
  default_organisation_id: string
  site_ids: string[]
  default_site_id: string
  brand_ids: string[]
  default_brand_id: string
}

type TrybeProfileClaims = IdTokenClaims & OrganisationClaims

export const useUser = () => {
  const { user } = useAuth()

  return user.profile as TrybeProfileClaims
}

export const useIsTrybeOrg = () => {
  const { default_organisation_id: organisationId } = useUser()

  return organisationId === '00000000-0000-0000-0000-000000000000'
}

export const useUserHasTrybeEmail = () => {
  const { email } = useUser()

  return email.endsWith('@try.be')
}

export const useIntercomUserHash = () => {
  const { user } = useAuth()

  if (!user?.access_token) {
    return null
  }

  const decodedToken = jwtDecode<{ intercom_user_hash?: string }>(user.access_token)

  return decodedToken.intercom_user_hash
}
