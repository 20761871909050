import { useInfiniteQuery, useQuery } from 'react-query'
import { AuditLogsApi } from '@trybeapp/sdk.shop'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'

const api = new AuditLogsApi()

export const useGetAuditLogs = (subjectId: string) => {
  const [page] = usePageQueryParam()
  const params = { subjectId, page }

  return useQuery(['auditLogs', params], fetchAuditLogs)
}

export const useInfiniteAuditLogs = (subjectId: string, options = {}) => {
  return useInfiniteQuery(['auditLogs', { subjectId }], fetchAuditLogs, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
    ...options,
  })
}

const fetchAuditLogs = async ({ queryKey, pageParam: page = 1 }) => {
  const params = queryKey[1]
  const response = await api.auditLogsIndex({ page, ...params })

  return {
    data: response.data,
    meta: response.meta,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}
