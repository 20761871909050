import { Fragment, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSidebarLinks } from 'hooks/UseSidebarLinks'
import { useUser } from 'hooks/UseUser'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { MenuIcon, SelectorIcon } from '@heroicons/react/outline'
import { useCurrentSite } from 'contexts/SiteConfig'
import { Dialog, Popover, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { ChevronDownIcon as ChevronDownIconSolid } from '@heroicons/react/solid'
import { Logo } from './Logo'
import { Modal } from 'ui/Modal'
import { ResourceListItem } from 'ui/components/ResourceListItem'
import classNames from 'classnames'
import { useSidebar } from './contexts'
import { ConditionalWrapper } from 'ui/components/ConditionalWrapper'
import { usePopper } from 'react-popper'
import { createPortal } from 'react-dom'
import { CollapseButton } from './CollapseButton'
import { useGetUserSites } from 'api/Users'
import { Badge } from 'ui/components/Badge'

const Link = ({ link, pinToBottom }) => {
  const { pathname } = useLocation()
  const { minimised, lightTheme } = useSidebar()
  const [expanded, setExpanded] = useState(false)

  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 10],
        },
      },
    ],
  })

  useEffect(() => {
    const foundAsChild =
      (link.childLinks ?? []).find((childLink) => {
        return pathname.startsWith(childLink.to)
      }) !== undefined

    if (link.exact) {
      setExpanded(pathname === link.to)
    } else {
      setExpanded(!link.exact && (pathname.startsWith(link.to) || foundAsChild))
    }
  }, [pathname])

  const hasChildren = link.childLinks?.length > 0

  const childLinks = ({ onClick = null }) => (
    <div
      className={`border-l-2 ${
        lightTheme ? 'border-gray-200' : 'border-white/10'
      } dark:border-slate-800 ml-5`}
    >
      {(link.childLinks ?? []).map((childLink) => {
        return (
          <NavLink
            exact={childLink.exact}
            key={childLink.to}
            to={childLink.to}
            onClick={onClick}
            className={(active) =>
              classNames(
                'transition text-sm relative w-full group flex items-center p-2 px-3 font-medium',
                {
                  'before:w-2 before:h-2 before:-left-[5px] before:rounded-full before:absolute':
                    true,
                  'mt-auto': pinToBottom,
                  'hover:before:bg-gray-200 dark:hover:before:bg-slate-600': !active && lightTheme,
                  'hover:before:bg-white/30': !active && !lightTheme,
                  'before:bg-violet': active,
                }
              )
            }
          >
            <span className="flex-1">{childLink.title}</span>

            {(childLink.isNew || childLink.isBeta) && (
              <div className="flex-shrink-0 dark">
                {childLink.isNew && <Badge label="New!" variant="info" />}
                {childLink.isBeta && <Badge label="Beta" variant="warning" />}
              </div>
            )}
          </NavLink>
        )
      })}
    </div>
  )

  return (
    <>
      <ConditionalWrapper
        condition={hasChildren && minimised}
        wrapper={(children) => (
          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button
                  ref={(r) => setReferenceElement(r)}
                  className={classNames(
                    'relative dark:hover:bg-violet/25 w-full group flex items-center p-2 font-medium rounded-md',
                    {
                      'hover:bg-violet-100': lightTheme,
                      'hover:bg-white/10': !lightTheme,
                      'text-sm': true,
                      'mt-auto': pinToBottom,
                      'bg-violet-100 dark:bg-violet/25': lightTheme && (expanded || open),
                      'bg-white/10': !lightTheme && (expanded || open),
                      'justify-center': minimised,
                      'space-x-2': !minimised,
                    }
                  )}
                >
                  {children({ popoverIsOpen: open })}
                </Popover.Button>
                {createPortal(
                  <Popover.Panel
                    ref={(r) => setPopperElement(r)}
                    style={styles.popper}
                    {...attributes.popper}
                    className={`z-50 style-reset w-60 rounded-md ml-4 border shadow-lg ${
                      lightTheme
                        ? 'bg-white border-gray-200'
                        : 'bg-grape border-white/10 text-white/60'
                    }`}
                  >
                    {childLinks({ onClick: close })}
                  </Popover.Panel>,
                  document.body
                )}
              </>
            )}
          </Popover>
        )}
        falseWrapper={(children) => (
          <NavLink
            to={`${link.to}`}
            title={link.title}
            exact={link.exact}
            className={(active) =>
              classNames(
                'relative dark:hover:bg-violet/25 w-full group flex items-center p-2 font-medium rounded-md',
                {
                  'hover:bg-violet-100': lightTheme,
                  'hover:bg-white/10': !lightTheme,
                  'text-sm': true,
                  'mt-auto': pinToBottom,
                  'hover:bg-violet-100 bg-violet-100 dark:bg-violet/25':
                    lightTheme && (expanded || active),
                  'bg-white/10': !lightTheme && (expanded || active),
                  'justify-center': minimised,
                  'space-x-2': !minimised,
                }
              )
            }
          >
            {children({ popoverIsOpen: false })}
          </NavLink>
        )}
      >
        {({ popoverIsOpen }) => (
          <>
            <LinkIcon expanded={expanded || popoverIsOpen} link={link} />
            <span className={classNames({ 'md:hidden': minimised }, 'truncate flex-1')}>
              {link.title}
            </span>
            {link.childLinks?.length > 0 && (
              <span
                className={classNames({
                  'flex-shrink-0 ml-auto': true,
                  'group-hover:text-violet': lightTheme,
                  'group-hover:text-white': !lightTheme,
                  'text-violet': lightTheme && (expanded || popoverIsOpen),
                  'text-white': !lightTheme && (expanded || popoverIsOpen),
                  'text-gray-300': lightTheme && !expanded && !popoverIsOpen,
                  'text-white/40': !lightTheme && !expanded && !popoverIsOpen,
                  'absolute -bottom-1 -right-1 -rotate-45': minimised,
                })}
              >
                {minimised && <ChevronDownIconSolid className={`w-5 h-5`} />}

                {!minimised && <ChevronDownIcon className={`w-5 h-5`} />}
              </span>
            )}

            {(link.isNew || link.isBeta) && !minimised && (
              <div className="flex-shrink-0 dark">
                {link.isNew && <Badge label="New!" variant="info" />}
                {link.isBeta && <Badge label="Beta" variant="warning" />}
              </div>
            )}
          </>
        )}
      </ConditionalWrapper>

      {hasChildren && (
        <Transition
          show={expanded && !minimised}
          enter="transition-all ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {childLinks({})}
        </Transition>
      )}
    </>
  )
}

const LinkIcon = ({ expanded, link }) => {
  const { lightTheme } = useSidebar()

  if (link.iconExtra) {
    return (
      <div
        className={`${
          lightTheme
            ? 'text-gray-400 group-hover:text-gray-600'
            : 'text-white/50 group-hover:text-white'
        } dark:text-slate-600 group-focus:text-white flex-shrink-0 flex relative`}
      >
        <LinkHeroIcon expanded={expanded} icon={link.heroIcon} />
        {link.iconExtra && <link.iconExtra />}
      </div>
    )
  }

  return <LinkHeroIcon expanded={expanded} icon={link.heroIcon} />
}

const LinkHeroIcon = ({ expanded, icon: Icon }) => {
  const { lightTheme } = useSidebar()

  return (
    <Icon
      className={classNames(`flex-shrink-0 h-6 w-6`, {
        'group-hover:text-violet': lightTheme,
        'group-hover:text-white': !lightTheme,
        'text-violet': expanded && lightTheme,
        'text-white': expanded && !lightTheme,
        'text-gray-400 dark:text-slate-600': !expanded && lightTheme,
        'text-white/30': !expanded && !lightTheme,
      })}
      aria-hidden="true"
    />
  )
}

const SiteSwitcherOverlay = () => {
  const [opening, setOpening] = useState(false)
  const userId = useUser()?.sub
  const { data: { data: sites = [] } = {} } = useGetUserSites(userId, {
    cacheTime: Infinity,
    staleTime: 60 * 60 * 1000, // 60 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  const { setActiveSiteId } = useSiteConfig()
  const site = useCurrentSite()
  const { minimised, lightTheme } = useSidebar()

  if (sites.length === 1) {
    return null
  }

  return (
    <>
      <button
        onClick={() => setOpening(true)}
        className={classNames({
          'justify-center': minimised,
          'bg-white border-gray-200 hover:border-gray-300': lightTheme,
          'bg-white/20 border-transparent text-white/80': !lightTheme,
          'group dark:bg-slate-900 shadow-sm border dark:border-slate-700 dark:hover:border-slate-600 p-2 rounded-md text-left w-full flex space-x-3 text-sm items-center':
            true,
        })}
      >
        {!minimised && (
          <div className="flex-1 pl-1">
            <div className="font-medium truncate">{site?.name}</div>
          </div>
        )}

        <div className="flex-shrink-0">
          <SelectorIcon
            className={`w-6 h-6 ${
              lightTheme ? 'text-gray-400' : 'text-white/40 group-hover:text-white'
            }`}
          />
        </div>
      </button>

      <Modal isOpen={opening} title="Select site" onClose={() => setOpening(false)} styleReset>
        {sites.map((site) => (
          <ResourceListItem
            key={site.id}
            title={site.name}
            showDisclosureArrow
            onClick={() => {
              setActiveSiteId(site.id)
              setOpening(false)
            }}
          />
        ))}
      </Modal>
    </>
  )
}

const SidebarInner = () => {
  const links = useSidebarLinks()
  const { minimised, lightTheme } = useSidebar()

  return (
    <div
      className={`flex-1 flex flex-col h-screen dark:bg-slate-900 ${
        lightTheme ? 'bg-white text-gray-700' : 'bg-grape text-white/60'
      }`}
    >
      <div className="px-2 flex-1 flex flex-col pb-4 overflow-y-auto">
        <div
          className={`px-2 pt-3 sticky top-0 dark:bg-slate-900 z-10 ${
            lightTheme ? 'bg-white' : 'bg-grape'
          }`}
        >
          <div className="flex items-center justify-between space-x-2 mb-4">
            <Logo />
            <CollapseButton />
          </div>
          {import.meta.env.VITE_APP_PROJECT_ENVIRONMENT !== 'production' && (
            <div
              className="mb-2 text-center truncate text-white text-xs font-bold uppercase bg-violet px-2 py-1 bottom-0 rounded-md"
              title={import.meta.env.VITE_APP_PROJECT_ENVIRONMENT.toUpperCase()}
            >
              {import.meta.env.VITE_APP_PROJECT_ENVIRONMENT.substr(0, minimised ? 1 : 100)}
            </div>
          )}
          <div className="mb-2">
            <SiteSwitcherOverlay />
          </div>
        </div>
        <nav className="flex-1 flex flex-col px-2 gap-y-1">
          {links.map((link, i) => (
            <Link link={link} key={link.to} pinToBottom={link.to === '/settings'} />
          ))}
        </nav>
      </div>
    </div>
  )
}

export const MobileSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  let closeButtonRef = useRef(null)
  const { pathname } = useLocation()

  useEffect(() => {
    // The pathname has changed, so close the mobile menu.
    setSidebarOpen(false)
  }, [pathname])

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          initialFocus={closeButtonRef}
          as="div"
          open={true}
          className="fixed inset-0 flex z-40 style-reset"
          onClose={() => setSidebarOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-grape-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                    onClick={() => setSidebarOpen(false)}
                    ref={closeButtonRef}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center">
                <SidebarInner />
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
      <button
        className="p-2 rounded-full text-gray-500"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="w-6 h-6" aria-hidden="true" />
      </button>
    </>
  )
}

export const Sidebar = () => {
  const { minimised } = useSidebar()

  return (
    <>
      {/* Desktop sidebar */}
      <div className="hidden md:flex md:flex-shrink-0 sticky top-0 style-reset">
        <div
          className={`flex flex-col border-r border-gray-200 dark:border-transparent transition-width ${
            minimised ? 'w-20' : 'w-60'
          }`}
        >
          <SidebarInner />
        </div>
      </div>
    </>
  )
}
