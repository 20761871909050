import React, { useEffect, useState, useCallback } from 'react'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { inputClasses } from 'ui/components/Form/Input'
import { useCurrentSite } from 'contexts/SiteConfig'
import useDebounce from 'hooks/Debounce'

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

export const GoogleAddressAutoComplete = ({ onPlaceSelect }: Props) => {
  const places = useMapsLibrary('places')
  const site = useCurrentSite()
  const [inputValue, setInputValue] = useState<string>('')
  const debouncedInputValue = useDebounce(inputValue, 500)

  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)

  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null)

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([])

  useEffect(() => {
    if (!places) return

    setAutocompleteService(new places.AutocompleteService())
    setPlacesService(
      new places.PlacesService(
        document.getElementById('googlemaps-attribution-container') as HTMLDivElement
      )
    )
    setSessionToken(new places.AutocompleteSessionToken())

    return () => setAutocompleteService(null)
  }, [places])

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([])
        return
      }

      const request = { input: inputValue, region: `${site.country_code ?? 'GB'}`, sessionToken }
      const response = await autocompleteService.getPlacePredictions(request)

      setPredictionResults(response.predictions)
    },
    [autocompleteService, sessionToken]
  )

  useEffect(() => {
    if (debouncedInputValue) {
      fetchPredictions(debouncedInputValue)
    } else {
      setPredictionResults([])
    }
  }, [debouncedInputValue, fetchPredictions])

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return

      const detailRequestOptions: google.maps.places.PlaceDetailsRequest = {
        placeId,
        fields: ['address_components'],
        sessionToken,
      }

      const detailsRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
        onPlaceSelect(placeDetails)
        setPredictionResults([])
        setInputValue(placeDetails?.formatted_address ?? '')
        setSessionToken(new places.AutocompleteSessionToken())
      }

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback)
    },
    [onPlaceSelect, places, placesService, sessionToken]
  )

  return (
    <div>
      <div className="autocomplete-container">
        <Combobox as="div" value={inputValue}>
          {() => {
            return (
              <div className="relative mt-1">
                <Combobox.Input
                  className={inputClasses}
                  value={inputValue}
                  placeholder={'Type to search'}
                  onChange={(event) => setInputValue(event.target.value)}
                />
                {predictionResults.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {predictionResults.map((result) => (
                      <Combobox.Option
                        key={result.place_id}
                        value={result}
                        onClick={() => handleSuggestionClick(result.place_id)}
                        className={({ active }) =>
                          classNames(
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            <div className="flex">
                              <span className={classNames('truncate', selected && 'font-semibold')}>
                                {result.description}
                              </span>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            )
          }}
        </Combobox>
      </div>
      <div id="googlemaps-attribution-container"></div>
    </div>
  )
}
