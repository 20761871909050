import { createContext, isValidElement, useCallback, useContext, useEffect, useState } from 'react'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { set } from 'lodash'

export interface WizardStepProps {
  name: string
  onSelect?: unknown
  [key: string]: any
}

const WizardContext = createContext({})

export const useWizard: any = () => useContext(WizardContext)

const WizardContextProvider = ({ children }) => {
  const [values, setValues] = useState({})

  // Set the property at `key` to `value`, with dot notation
  const setValue = (key, value) => {
    const newValues = { ...values }
    set(newValues, key, value)
    setValues(newValues)
  }

  const value = {
    values,
    setValues,
    setValue,
  }

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
}

export const WizardStep: React.FC<WizardStepProps> = ({ children }) => <>{children}</>

export const Wizard = (props: any) => {
  return (
    <WizardContextProvider>
      <WizardInner {...props} />
    </WizardContextProvider>
  )
}

const WizardInner = ({
  isOpen,
  onClose,
  title,
  children = [],
  displayButtons = true,
  finishButtonLabel = 'Finish',
  onBeforeStepChange = null,
  canGoToStep = null,
  canGoBackStep = null,
  onStepChange = null,
  activeStep,
  onFinish,
  finishLoading = false,
  isLoading = false,
  canNavigateFromSteps = false,
}) => {
  const renderedChildren = Array.isArray(children) ? children : [children]
  const { values: context, setValue } = useWizard()
  const { currentStep = 0 } = context
  const setCurrentStepCallback = useCallback(
    (step) => {
      setValue('currentStep', step)
    },
    [setValue]
  )

  const setCurrentStep = (step) => {
    setCurrentStepCallback(step)

    if (typeof onStepChange === 'function') {
      onStepChange(step)
    }
  }

  const validChildren = renderedChildren.filter((child) => isValidElement(child))

  useEffect(() => {
    if (typeof activeStep !== 'undefined' && activeStep !== currentStep) {
      setCurrentStep(activeStep)
    }
  }, [activeStep, currentStep, setCurrentStep])

  const handleNextStep = () => {
    const nextStep = currentStep + 1

    if (typeof onBeforeStepChange === 'function' && onBeforeStepChange(nextStep) === false) {
      // The onBeforeStep change returned false, meaning we shouldn't progress forward.
      return
    }

    if (
      context.onBeforeStepChange &&
      typeof context.onBeforeStepChange[currentStep] === 'function' &&
      context.onBeforeStepChange[currentStep]() === false
    ) {
      return
    }

    if (checkStageAllowed(nextStep) === false) {
      return
    }

    setCurrentStep(nextStep)
  }

  const checkStageAllowed = (newStage) => {
    if (typeof canGoToStep === 'function') {
      return canGoToStep(newStage)
    }

    return true
  }

  const checkBackStageAllowed = (oldStage) => {
    if (typeof canGoToStep === 'function') {
      return canGoBackStep(oldStage)
    }

    return true
  }

  const handlePreviousStep = () => {
    if (onBeforeStepChange && !onBeforeStepChange(currentStep - 1)) {
      // The onBeforeStep change returned false, meaning we shouldn't progress forward.
      return
    }

    setCurrentStep(currentStep - 1)
  }

  const handleClose = () => {
    setCurrentStep(0)

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  const handleFinish = () => {
    if (typeof onFinish === 'function') {
      onFinish(setCurrentStep)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      size="3xl"
      showHideIcon={false}
      styleReset
    >
      <div className="grid md:grid-cols-3 md:space-x-6">
        <div className="col-span-2">
          {isLoading && <LoadingPane onClose={onClose} />}
          {!isLoading && (
            <>
              <div className="flex">
                {validChildren.map((child, index) => (
                  <Transition show={index === currentStep} key={index} className="w-full">
                    <div className="min-h-[400px]">{child}</div>
                  </Transition>
                ))}
              </div>
              {displayButtons && (
                <ModalButtons>
                  {currentStep < validChildren.length - 1 && (
                    <Button
                      label="Next"
                      disabled={!checkStageAllowed(currentStep + 1)}
                      onClick={handleNextStep}
                    />
                  )}

                  {currentStep === validChildren.length - 1 && (
                    <Button
                      label={finishButtonLabel}
                      onClick={handleFinish}
                      loading={finishLoading}
                      variant="primary"
                    />
                  )}

                  <Button
                    disabled={currentStep === 0 || !checkBackStageAllowed(currentStep - 1)}
                    label="Back"
                    onClick={handlePreviousStep}
                  />
                  <Button label="Close" onClick={handleClose} variant="ghost" />
                </ModalButtons>
              )}
            </>
          )}
        </div>

        <div className="relative pl-3 hidden sm:block">
          <div className="bg-violet-50 fixed ml-3 left-2/3 right-0 top-0 bottom-0 z-0 rounded-r-lg overflow-hidden">
            <StepIndicator
              setCurrentStep={canNavigateFromSteps ? setCurrentStep : undefined}
              steps={validChildren.map((child, index) => ({
                name: child.props.name,
                status:
                  currentStep === index ? 'current' : currentStep > index ? 'complete' : 'upcoming',
              }))}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const StepIndicator = ({ steps, setCurrentStep }) => (
  <div className="p-6 py-8">
    <nav aria-label="Progress">
      <ol className="space-y-6">
        {steps.map((step, index) => (
          <li key={index}>
            {step.status === 'complete' ? (
              <a
                href={step.href}
                className={`${
                  setCurrentStep === undefined ? '' : 'cursor-pointer hover:opacity-80'
                } group`}
                onClick={() => (setCurrentStep ? setCurrentStep(index) : null)}
              >
                <span className="flex items-start">
                  <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                    <CheckCircleIcon className="h-full w-full text-violet" aria-hidden="true" />
                  </span>
                  <div className="ml-3">
                    <div className="text-xs uppercase text-gray-400">Step {index + 1}</div>
                    <div className="text-sm font-medium text-gray-500">{step.name}</div>
                  </div>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a href={step.href} className="flex items-start" aria-current="step">
                <span
                  className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                  aria-hidden="true"
                >
                  <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                  <span className="relative block w-2 h-2 bg-violet rounded-full" />
                </span>
                <div className="ml-3">
                  <div className="text-xs uppercase text-gray-400">Step {index + 1}</div>
                  <div className="text-sm font-medium text-violet">{step.name}</div>
                </div>
              </a>
            ) : (
              <a href={step.href} className="group">
                <div className="flex items-start">
                  <div
                    className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                    aria-hidden="true"
                  >
                    <div className="h-2 w-2 bg-gray-300 rounded-full" />
                  </div>
                  <div className="ml-3">
                    <span className="text-xs uppercase text-gray-400">Step {index + 1}</span>
                    <p className="text-sm font-medium text-gray-500">{step.name}</p>
                  </div>
                </div>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  </div>
)

export const LoadingPane = ({ onClose }) => {
  return (
    <div className="flex flex-col min-h-[400px]">
      <div className="flex-grow">
        <div className="flex flex-col space-y-2 animate-pulse">
          <div className="h-12 bg-gray-200" />
          <div className="h-12 bg-gray-200" />
          <div className="h-12 bg-gray-200" />
          <div className="h-12 bg-gray-200" />
          <div className="h-12 bg-gray-200" />
        </div>
      </div>
      <ModalButtons>
        <Button label="Close" onClick={onClose} variant="ghost" />
      </ModalButtons>
    </div>
  )
}
