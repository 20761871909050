import { useMemo } from 'react'
import {
  STATUS_SUBMITTED,
  STATUS_NEW,
  STATUS_SETTLED,
  STATUS_CONFIRMED,
  STATUS_RESERVED,
  STATUS_RESERVATION_EXPIRED,
  STATUS_CANCELLED,
  STATUS_IN_PROGRESS,
  STATUS_NO_SHOW,
} from 'constants/orderStatuses'
import { capitalCase } from 'change-case'
import { Badge } from 'ui/components/Badge'
import { useAllActiveOrderItemDates } from './hooks'
import { OrderItemsSection } from 'screens/OrderDetails/components/Overview/OrderItemsSection'
import { Alert } from 'ui/components/Alert'
import { useGetOrder } from 'api/Orders'
import { useParams } from 'react-router-dom'

export const Overview = () => {
  const { orderId } = useParams()
  const { data: { data: order = {} } = {} } = useGetOrder(orderId)

  return (
    <>
      <div className="w-full pr-3 overflow-visible mx-auto">
        <MultipleDatesAlert />
        {!order.is_promo_code_discount_applied && order.applied_promo_code_id && (
          <ItemsNotValidForPromoCodeDiscountAlert />
        )}
        <OrderItemsSection />
      </div>
    </>
  )
}

const MultipleDatesAlert = () => {
  const dates = useAllActiveOrderItemDates()

  if (dates.length <= 1) return null

  return (
    <div className="my-4">
      <Alert variant="warning" border title="Some of these items are on different dates." />
    </div>
  )
}

const ItemsNotValidForPromoCodeDiscountAlert = () => {
  return (
    <div className="my-4">
      <Alert
        variant="warning"
        border
        title="There are items in this order which are currently not valid for the discount from this promo code."
      />
    </div>
  )
}

export const StatusTag = ({ status = '', size = 'md', ...props }) => {
  status = status ?? ''

  const variant = useMemo(() => {
    switch (status) {
      case STATUS_SETTLED:
      case STATUS_CONFIRMED:
        return 'success'
      case STATUS_SUBMITTED:
        return 'info'
      case STATUS_NEW:
      case STATUS_NO_SHOW:
      case STATUS_CANCELLED:
        return 'danger'
      case STATUS_IN_PROGRESS:
      case STATUS_RESERVED:
      case STATUS_RESERVATION_EXPIRED:
      default:
        return 'warning'
    }
  }, [status])

  const label = useMemo(() => friendlyOrderStatus(status), [status])

  return <Badge variant={variant} label={label} />
}

export const friendlyOrderStatus = (status) => {
  switch (status) {
    case STATUS_SETTLED:
      return 'Checked out'
    case STATUS_RESERVATION_EXPIRED:
      return 'Res. expired'
    default:
      return capitalCase(status.replace('_', ' '))
  }
}

export default Overview
