import moment from 'moment'
import { DateTime, SystemZone } from 'luxon'
import { groupBy, map } from 'lodash'

export const dateTimeToDateObjectAndTimeString = (date) => {
  const dateObject = date instanceof DateTime ? date : DateTime.fromJSDate(date)

  return [dateObject.startOf('day').toJSDate(), dateObject.toFormat('HH:mm')]
}

export const dateAndTimeToMoment = (date, time) => {
  const dateObject = date instanceof DateTime ? date : DateTime.fromJSDate(date)

  const timeMoment = moment(time, 'HH:mm')

  return dateObject.set({
    hours: timeMoment.hours(),
    minutes: timeMoment.minutes(),
  })
}

export const minutesToHoursWithHalves = (mins) => {
  if (!mins) {
    return 0
  }

  return Math.round((mins / 60) * 2) / 2
}

export const minutesToHoursAndMins = (mins) => {
  if (!mins) {
    return [0, 0]
  }

  return [Math.floor(mins / 60), mins % 60]
}

export const minutesToFormattedHoursAndMins = (inputMins) => {
  const [hours, mins] = minutesToHoursAndMins(inputMins)

  return hours + ':' + String(mins).padStart(2, '0')
}

export const minutesToFriendlyDuration = (inputMins, t) => {
  const [hours, mins] = minutesToHoursAndMins(inputMins)

  return inputMins < 90
    ? t('date_utils.minutes_to_friendly_duration.below_90', { minutes: inputMins })
    : t('date_utils.minutes_to_friendly_duration.90_and_above', { hours: hours, minutes: mins })
}

export const weekdaysToHumanFriendly = (weekdays, t) => {
  const uniqueWeekdays = [...new Set(weekdays)].map((weekday) => weekday.toLowerCase())

  const allWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  if (uniqueWeekdays.length === 1) {
    return t(`date_utils.weekdays_to_human_friendly.${uniqueWeekdays[0]}`)
  }

  if (uniqueWeekdays.length === 7) {
    return t('date_utils.weekdays_to_human_friendly.every_day')
  }

  if (
    uniqueWeekdays.length === 2 &&
    uniqueWeekdays.indexOf('saturday') >= 0 &&
    uniqueWeekdays.indexOf('sunday') >= 0
  ) {
    return t('date_utils.weekdays_to_human_friendly.weekends')
  }

  const weekdayIndexes = uniqueWeekdays.map((weekday) => allWeekdays.indexOf(weekday)).sort()

  if (uniqueWeekdays.length === 2) {
    return (
      t(`date_utils.weekdays_to_human_friendly.${allWeekdays[weekdayIndexes[0]]}`) +
      ' ' +
      t('date_utils.weekdays_to_human_friendly.and') +
      ' ' +
      t(
        `date_utils.weekdays_to_human_friendly.${
          allWeekdays[weekdayIndexes[weekdayIndexes.length - 1]]
        }`
      )
    )
  }

  const isConsecutive =
    weekdayIndexes[weekdayIndexes.length - 1] - weekdayIndexes[0] === weekdayIndexes.length - 1

  if (isConsecutive) {
    return `${t(`date_utils.weekdays_to_human_friendly.${allWeekdays[weekdayIndexes[0]]}`)} ${t(
      'date_utils.weekdays_to_human_friendly.to'
    )} ${t(
      `date_utils.weekdays_to_human_friendly.${
        allWeekdays[weekdayIndexes[weekdayIndexes.length - 1]]
      }`
    )}`
  }

  return uniqueWeekdays
    .map((weekday) => t(`date_utils.weekdays_to_human_friendly.${weekday}`))
    .join(', ')
}

export const recurrenceRulesToHumanFriendly = (rules) => {
  const groupedByTime = groupBy(rules, 'start_time')
  const humanFriendly = []

  for (const time in groupedByTime) {
    const weekdays = map(groupedByTime[time], 'weekday')
    humanFriendly.push(weekdaysToHumanFriendly(weekdays) + ' at ' + time)
  }

  return humanFriendly
}

export const minsToFriendlyDuration = (mins) => {
  if (mins < 60) {
    return `${mins} mins`
  }

  const hoursPart = Math.floor(mins / 60)
  const minsPart = mins % 60

  return `${hoursPart}h ${minsPart}m`
}

export const rangeToHumanFriendly = (dateFrom, dateTo) => {
  if (dateFrom && dateTo) {
    return `${DateTime.fromJSDate(dateFrom, {
      zone: new SystemZone(),
    }).toLocaleString()} to ${DateTime.fromJSDate(dateTo, {
      zone: new SystemZone(),
    }).toLocaleString()}`
  } else if (dateFrom) {
    return `Dates after ${DateTime.fromJSDate(dateFrom, {
      zone: new SystemZone(),
    }).toLocaleString()}`
  } else if (dateTo) {
    return `Dates before ${DateTime.fromJSDate(dateTo, {
      zone: new SystemZone(),
    }).toLocaleString()}`
  }

  return null
}

export const rangeToHumanFriendlyLong = (dateFrom, dateTo, t) => {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  if (dateFrom && dateTo) {
    return t('date_utils.range_to_human_friendly_long.range', {
      dateFrom: DateTime.fromJSDate(dateFrom, {
        zone: new SystemZone(),
      }).toLocaleString(options),
      dateTo: DateTime.fromJSDate(dateTo, {
        zone: new SystemZone(),
      }).toLocaleString(options),
    })
  } else if (dateFrom) {
    return t('date_utils.range_to_human_friendly_long.dates_after', {
      dateFrom: DateTime.fromJSDate(dateFrom, {
        zone: new SystemZone(),
      }).toLocaleString(options),
    })
  } else if (dateTo) {
    return t('date_utils.range_to_human_friendly_long.dates_before', {
      dateTo: DateTime.fromJSDate(dateTo, {
        zone: new SystemZone(),
      }).toLocaleString(options),
    })
  } else if (dateFrom === null && dateTo === null) {
    return t('date_utils.range_to_human_friendly_long.all_dates')
  }

  return null
}

// DateTime objects get created in the site's timezone but using toJSDate() will convert
// back to the system's timezone, which causes problems if the site and computer are in
// different timezones.
//
// For example, if the site is in Paris timezone but the computer is using London time,
// a DateTime object that's 2023-07-10T00:00:00 in Europe/Paris will be converted to a
// JS date of 2023-07-09T23:00:00 in Europe/London (the day before).
//
// We can use this function to get a JS Date that's the same day as the DateTime object
// but in the system timezone, which is useful for example if we're then passing that JS
// date to be used as a date query param.
export const dateTimeToJsDate = (dateTimeObj) => {
  return new Date(dateTimeObj.year, dateTimeObj.month - 1, dateTimeObj.day)
}

// In cases where we have a JS date at midnight (such as from the datepicker), the
// date will be set in the timezone of the user's system (i.e. their computer
// setting). Converting that date to a DateTime object sets it to use the site's
// timezone, which changes the time and means that formatting the date will display
// the wrong time, and if the site timezone is behind the system timezone it will
// also display the date as the day before what was intended.
//
// By specifying 'system' here we keep the date string on the date that was intended.
export const toIsoDate = (date) => {
  return DateTime.fromJSDate(date, {
    zone: new SystemZone(),
  }).toISODate()
}

// In cases where we have a JS date, it will have been set in the timezone of the
// user's system (i.e. their computer setting). Converting that date to a DateTime
// object sets it to use the site's timezone, and changes the time accordingly.
//
// This instantiates the DateTime keeping the system timezone and then converts the
// date to the site's timezone without changing the time, so if a user in London
// picks midnight for a site in New York, that becomes midnight in New York.
export const systemDateInSiteZone = (date) => {
  return DateTime.fromJSDate(date, { zone: new SystemZone() }).setZone('local', {
    keepLocalTime: true,
  })
}

// If there's a difference between the site and the user's system, JS dates
// representing a time in the site zone will get instantiated in the current
// user's timezone. Midnight in Brisbane will be 15:00 the day before in
// the UK, so anything using the JS date (eg a date picker) might display the
// date before.
//
// This instiates a DateTime in the site's timezone and then converts the date
// to the system timezone without changing the time, so if a user in London
// loads a report with a date param as midnight for a site in Brisbane, that
// becomes midnight in London.
export const siteDateInSystemZone = (date) => {
  const isSiteTimezone =
    date.getTimezoneOffset() ===
    DateTime.local()
      .set({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      })
      .startOf('day')
      .toJSDate()
      .getTimezoneOffset()

  return DateTime.fromJSDate(date).setZone(new SystemZone(), {
    keepLocalTime: !isSiteTimezone,
  })
}

// When the SDK has a property in `date` format it is returned from the API as Y-m-d
// and the SDK converts that to a JS date using `new Date(Y-m-d)`. When JS isn't
// given a time or timezone, it assumes a UTC date and instantiates that in the
// system timezone, so if your browser is in a New York timezone:
// 2024-03-21 ⇒ Wed Mar 20 2024 19:00:00 GMT-0400 (Eastern Daylight Time)
//
// It's more useful to have the date at midnight in the system's timezone, so we
// 1. Take the JS object that's at 7pm NYC time
// 2. Convert it to UTC so it moves back to midnight
// 3. Convert it back to the system's timezone, keeping the time, so it's 00:00 NYC
//
// Usually the important thing about these dates is that they can be displayed as
// the same Y-m-d date that was returned, so having them in the user's timezone
// is more useful than converting them to the site's timezone.
export const sdkDateToSystemDateTime = (date) => {
  return DateTime.fromJSDate(date).setZone('UTC').setZone(new SystemZone(), { keepLocalTime: true })
}

export const nowInSystemZone = () => {
  return siteDateInSystemZone(DateTime.local().toJSDate())
}

export const newSystemDate = (date) => {
  return DateTime.fromJSDate(date).setZone(new SystemZone())
}

export const friendlyDateTimeFormat = {
  day: 'numeric',
  month: 'short',
  hour: 'numeric',
  minute: 'numeric',
}

export const setTimeFromString = (date, timeString) => {
  const timeParts = timeString.split(':')

  return DateTime.fromJSDate(date).set({ hour: timeParts[0], minute: timeParts[1] }).toJSDate()
}
