import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

export const ExpiredRedirect = () => {
  const auth = useAuth()

  useEffect(() => {
    auth.signinRedirect({ state: window.location.href })
  }, [])

  return null
}
