import VendorFreshChat from 'react-freshchat'
import { useOrganisationConfig } from 'hooks/UseOrganisationConfig'
import { useUser } from 'hooks/UseUser'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'

export const FreshChat = () => {
  const { email, given_name: firstName, family_name: lastName, sub } = useUser()
  const { site, siteId } = useSiteConfig()
  const organisationId = useOrganisationConfig()
  const { name: siteName } = site || {}
  const featureFlags = useActiveFeatureFlags()

  if (featureFlags.length === 0 || featureFlags.includes('intercom')) return null

  if (!import.meta.env.VITE_APP_FRESHCHAT_TOKEN || !sub) return null

  return (
    <VendorFreshChat
      token={import.meta.env.VITE_APP_FRESHCHAT_TOKEN}
      host="https://wchat.eu.freshchat.com"
      email={email}
      firstName={firstName}
      lastName={lastName}
      config={{
        headerProperty: {
          hideChatButton: true,
        },
      }}
      onInit={(widget) => {
        widget.user.setProperties({
          externalId: sub,
          email: email,
          firstName: firstName,
          lastName: lastName,
          organisationId: organisationId,
          siteId: siteId,
          siteName: siteName,
        })
      }}
    />
  )
}
