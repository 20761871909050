import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Text } from '@trybeapp/ui.text'
import { STATUS_CANCELLED, STATUS_RESERVED, STATUS_CONFIRMED } from 'constants/orderStatuses'
import { TYPE_APPOINTMENT } from 'constants/offeringTypes'
import { Card } from '../../../components/Card'
import { useDeleteOrderItem } from 'api/OrderItems'
import { useOrderIsLocked, useOrderIsSettled } from '../hooks'
import { map, upperFirst } from 'lodash'
import { sentenceJoin } from 'utilities/StringUtils'
import { ItemActions } from './ItemActions'
import { DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon, CheckIcon } from '@heroicons/react/outline'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { StatusTag } from '../Overview'
import { formatMoney } from 'ui/components/Money'
import { useCurrentSite } from 'contexts/SiteConfig'

export const AppointmentEnquiryItemRow = ({ item }) => {
  const { orderId } = useParams()
  const { currency } = useCurrentSite()
  const { id: itemId, status: itemStatus, type_name: name = '', type_product_code: code } = item
  const itemIsCancelled = itemStatus === STATUS_CANCELLED
  const itemIsConfirmed = itemStatus === STATUS_CONFIRMED
  const canEdit =
    !itemIsCancelled && !itemIsConfirmed && !useOrderIsSettled() && !useOrderIsLocked()
  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Card className="my-2">
      <Card.Body>
        <div className="text-nude-700 text-sm font-bold mb-2">
          ENQUIRY: {name} {code && <>[{code}]</>}
        </div>
        <table className="table-fixed">
          <thead>
            <tr className="text-nude-400 uppercase font-semibold text-xs tracking-wider text-left">
              <th className="w-[13%]">Status</th>
              <th className="w-[29%]">Date and time</th>
              <th className="w-[31%]">Service</th>
              <th className="w-[27%]">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-nude-900">
              <td>
                <Status item={item} />
              </td>
              <td>
                <Times item={item} />
              </td>
              <td>{item.type_name}</td>
              <td>{formatMoney({ amount: item.total_cost, currency })}</td>
              <td className="text-right">
                <div className={itemIsConfirmed ? 'invisible' : ''}>
                  <ItemActions
                    item={item}
                    handleDelete={handleDelete}
                    isDeleting={isDeleting}
                    canChangePrice={false}
                  >
                    {canEdit && <EditItemDropdownOption item={item} />}
                    {canEdit && <ConfirmItemDropdownOption item={item} />}
                  </ItemActions>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Card.Body>
    </Card>
  )
}

export const Status = ({ item }) => {
  const { status: itemStatus } = item
  let displayStatus

  switch (itemStatus) {
    case STATUS_RESERVED:
      displayStatus = 'Pending'
      break

    case 'received':
      displayStatus = 'Received'
      break

    default:
      displayStatus = itemStatus
  }

  return <StatusTag status={displayStatus} />
}

const Times = ({ item }) => {
  const { purchasable_details: purchasableDetails } = item
  const timeFrom = DateTime.fromISO(purchasableDetails.time_from).toLocaleString(
    DateTime.TIME_SIMPLE
  )
  const timeTo = DateTime.fromISO(purchasableDetails.time_to).toLocaleString(DateTime.TIME_SIMPLE)
  const date = DateTime.fromISO(purchasableDetails.time_from).toLocaleString(DateTime.DATE_SHORT)

  return (
    <>
      {date} {timeFrom}-{timeTo}
    </>
  )
}

export const BookingOrderItemResourceSummary = ({ item }) => {
  const { item_type: itemType = '', booking_summary: bookingSummary = {} } = item

  const resourceString = useMemo(() => {
    const practitioners = bookingSummary.practitioners || []
    const room = bookingSummary.room || {}

    const strings = []

    if (practitioners.length > 0) {
      strings.push(`with ${sentenceJoin(map(practitioners, 'name'), ',', '&')}`)
    }

    if (room?.name) {
      strings.push(`in ${room.name}`)
    }

    return upperFirst(strings.join(' ') ?? '')
  }, [bookingSummary.practitioners, bookingSummary.room])

  if (itemType !== TYPE_APPOINTMENT || !resourceString) return null

  return (
    <Text variant="meta1" color="nude.500" lineHeight={1.2}>
      {resourceString}
    </Text>
  )
}

const EditItemDropdownOption = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleOnClick = () => {
    setValue('appointmentEnquiryWizard.editingEnquiry', item)
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}

const ConfirmItemDropdownOption = ({ item }) => {
  const { updateValues } = useOrderDetails()

  const handleOnClick = () => {
    updateValues({
      'appointmentEnquiryWizard.editingEnquiry': item,
      'appointmentEnquiryWizard.activeStep': 1,
    })
  }

  return <DropdownItem label="Confirm" onClick={handleOnClick} icon={CheckIcon} />
}
