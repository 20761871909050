import { INVENTORY_VIEW } from 'constants/permissions'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const Stock = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'InventoryStock' */ '../screens/InventoryV2/Stock').then((file) => {
    return { default: file.Stock }
  })
)

const StockOrders = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'InventoryStockOrders' */ '../screens/InventoryV2/StockOrders').then(
    (file) => {
      return { default: file.StockOrders }
    }
  )
)

const StockTakes = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'InventoryStockTakes' */ '../screens/InventoryV2/StockTakes/').then(
    (file) => {
      return { default: file.StockTakes }
    }
  )
)

export const useRoutes = () => {
  return [
    {
      path: '/inventory/stock-items',
      name: 'Stock items',
      component: Stock,
      exact: true,
      permissions: [INVENTORY_VIEW],
    },
    {
      path: '/inventory/stock-orders',
      name: 'Stock orders',
      component: StockOrders,
      exact: true,
      permissions: [INVENTORY_VIEW],
    },
    {
      path: '/inventory/stock-takes',
      name: 'Stocktakes',
      component: StockTakes,
      exact: true,
      permissions: [INVENTORY_VIEW],
    },
  ]
}
