import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from 'react-query'
import { OrdersApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { sdkDateToSystemDateTime } from 'utilities/DateUtils/dateUtils'
import { apiConfiguration, formatDateForApi } from 'utilities/TrybeApiClient'
import { ReportsApi } from '@trybeapp/sdk'

const api = new OrdersApi()
const tsApi = new ReportsApi(apiConfiguration)

export interface DateItineraryParams {
  date: Date
  siteId: string
}

export const useGetOrders = (params, options = {}) =>
  useQuery(
    ['orders', params],
    async () => {
      params.itemOfferingId = Array.isArray(params.itemOfferingId)
        ? params.itemOfferingId.join(',')
        : undefined

      const response = await api.ordersIndex(params)

      response.data.forEach((order) => formatOrderResponse(order))

      return response
    },
    options
  )

export const useGetOrder = (orderId, options: UseQueryOptions = {}): UseQueryResult<any> => {
  options.retry = (failureCount, error: any) => {
    return failureCount < 3 && error.status !== 404
  }

  options.enabled = !!orderId
  options.staleTime = options.staleTime ?? 1000 // 1 second

  return useQuery(
    ['orders', orderId],
    async () => {
      const response = await api.orderShow(orderId)

      formatOrderResponse(response.data)

      return response
    },
    options
  )
}

export const useGetOrderByRef = (orderRef, options = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['orders', { orderRef }],
    async () => await api.getOrderByRef(orderRef, { siteId }),
    options
  )
}

export const useAddOrder = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(async (params: any) => await api.orderStore({ site_id: siteId, ...params }), {
    onSuccess: (result) => {
      queryClient.invalidateQueries(['orders', result.data.id])
    },
  })
}

export const useSubmitOrder = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, any]) => {
      const [orderId, options = {}] = args
      return await api.orderSubmit(orderId, options)
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['orders', result.data.id])
      },
    }
  )
}

export const useRestoreOrder = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string]) => {
      const [orderId] = args
      return await api.orderRestore(orderId)
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['orders', result.data.id])
      },
    }
  )
}

export const useUpdateOrder = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, any]) => {
      const [orderId, order] = args
      return await api.orderUpdate(orderId, order)
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['orders', result.data.id])
      },
      onMutate: async (args: [string, any]) => {
        const [orderId, order] = args

        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        await queryClient.cancelQueries({ queryKey: ['orders', orderId] })

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(['orders', orderId])

        // Optimistically update to the new value
        queryClient.setQueryData(['orders', orderId], (old: any) => {
          return {
            ...old,
            data: {
              ...old.data,
              ...order,
            },
          }
        })

        // Return a context object with the snapshotted value
        return { previousData }
      },
    }
  )
}

export const useCancelOrder = () => {
  const queryClient = useQueryClient()

  return useMutation(async (orderId) => await api.orderCancel(orderId), {
    onSuccess: (result) => {
      queryClient.invalidateQueries(['orders', result.data.id])
    },
  })
}

export const useDeleteOrder = () => {
  const queryClient = useQueryClient()

  return useMutation(async (orderId) => await api.orderDestroy(orderId), {
    onSuccess: (result, args) => {
      queryClient.invalidateQueries(['orders', args])
    },
  })
}

export const useSettleOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (args: [string, any]) => {
      const [orderId, options = {}] = args
      return await api.orderSettle(orderId, options)
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['orders', result.data.id])
        queryClient.invalidateQueries(['calendarEvents'])
      },
    }
  )
}

export const useMarkOrderAsNoShow = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (args: [string]) => {
      const [orderId] = args
      return await api.orderNoShow(orderId)
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['orders', result.data.id])
        queryClient.invalidateQueries(['calendarEvents'])
      },
    }
  )
}

export const useLockOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(async (orderId) => await api.orderLock(orderId), {
    onSuccess: (result) => {
      queryClient.invalidateQueries(['orders', result.data.id])
      queryClient.invalidateQueries('calendarEvents')
    },
  })
}

export const useUnlockOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(async (orderId) => await api.orderUnlock(orderId), {
    onSuccess: (result) => {
      queryClient.invalidateQueries(['orders', result.data.id])
      queryClient.invalidateQueries('calendarEvents')
    },
  })
}

export const useGetOrderItineraryDoc = (orderId, options: UseQueryOptions = {}) => {
  options.cacheTime = options.cacheTime ?? 0

  return useQuery(
    ['orderItineraryDoc', orderId],
    async () => await api.orderItineraryDocGet(orderId),
    options
  )
}

export const useExportDateItinerary = (): UseMutationResult<any> => {
  const siteId = useCurrentSiteId()

  return useMutation(['ExportItinerary'], (params: DateItineraryParams) => {
    params.date = formatDateForApi(params.date)
    params.siteId = siteId

    return tsApi.getDateItineraryQueue(params)
  })
}

export const useGetOrderRefundableItems = (
  orderId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery(['orderRevenues', orderId], () => api.orderGetRefundableItems(orderId), options)

export const useGetOrderRevenues = (
  orderId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery(['orderRevenues', orderId], () => api.orderRevenues(orderId), options)

export const useGetOrderLedgerLines = (
  orderId,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery(['orderLedgerLines', orderId], () => api.orderLedgerLines(orderId), options)

export const useGetOrderLedgerLinePostings = (
  orderId,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery(
    ['orderLedgerLinePostings', orderId],
    () => api.orderLedgerLinePostings(orderId),
    options
  )

export const useOverpaymentToVoucher = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (args: any) => {
      const [orderId, params] = args
      return api.orderOverpaymentToVoucher(orderId, params)
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useUpdateOrderLeadBooker = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (args: [string, any]) => {
      const [orderId, options = {}] = args
      return api.orderUpdateLeadBooker(orderId, options)
    },
    {
      onSuccess: (result: any) => {
        queryClient.invalidateQueries(['orders', result.data.id])
        queryClient.invalidateQueries(['orderGuests', result.data.id])
      },
    }
  )
}

const formatOrderResponse = (order) => {
  const bookingItems = order.booking_items ?? []
  const packageItems = order.package_items ?? []

  bookingItems.forEach((item) => {
    item.date = sdkDateToSystemDateTime(item.date).toJSDate()
  })

  packageItems.forEach((item) => {
    item.date = sdkDateToSystemDateTime(item.date).toJSDate()
  })

  if (order.booking_items_start_date) {
    order.booking_items_start_date = sdkDateToSystemDateTime(
      order.booking_items_start_date
    ).toJSDate()
  }

  if (order.booking_items_end_date) {
    order.booking_items_end_date = sdkDateToSystemDateTime(order.booking_items_end_date).toJSDate()
  }
}
