import { useState, useEffect } from 'react'
import { Button } from 'ui/components/Button'
import { useCurrentOrder } from 'contexts/Order'
import { ErrorProvider } from 'contexts/Error'
import { ErrorAlert } from 'components/ErrorAlert'
import { DateTime } from 'luxon'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit'
import { useCurrentUserCan, withUserHasPermission } from 'hooks/UseCurrentUserCan'
import { INTAKE_FORMS_VIEW } from 'constants/permissions'
import { ApiErrorNotification, useNotificationContext } from 'ui/components/Notification'
import { useGetIntakeFormsForOrder, useVisitIntakeFormHtml } from 'api/VisitIntakeForm'
import { Table } from 'ui/components/Table'
import { H2 } from 'ui/components/Text/H2'
import { Alert } from 'ui/components/Alert'
import { PrinterIcon, ShareIcon } from '@heroicons/react/solid'
import { Card } from 'ui/components/Card'
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import { Badge } from 'ui/components/Badge'
import { useSudo } from 'components/SudoOverlay/hooks'
import NewWindow from '@trybeapp/react-new-window'

export const IntakeForms = () => {
  const { order = {} } = useCurrentOrder()
  const { id: orderId, new_intake_form_url: newIntakeFormUrl } = order
  const can = useCurrentUserCan()

  const {
    data: { data: intakeForms = [] } = {},
    status: loadingStatus,
    isLoading,
    error,
  } = useGetIntakeFormsForOrder(orderId, {
    enabled: !!orderId && can(INTAKE_FORMS_VIEW),
  })

  return (
    <div className="style-reset text-gray-900 pr-2 flex flex-col gap-y-2">
      <div className="style-reset text-gray-900">
        <div className="flex items-center my-2">
          <H2>Intake Forms</H2>
          {newIntakeFormUrl && (
            <div className="ml-auto">
              <CopyLink url={newIntakeFormUrl} />
            </div>
          )}
        </div>

        {loadingStatus === 'loading' ? (
          <Card className="bg-white">
            <div className="animate-pulse">
              <div className="w-32 h-5 bg-gray-100" />
              <div className="w-3/4 h-4 mt-3 bg-gray-100" />
            </div>
          </Card>
        ) : loadingStatus === 'error' ? (
          <ErrorProvider error={error}>
            <ErrorAlert />
          </ErrorProvider>
        ) : (
          <>
            <NoPermissionAlert />
            {!isLoading && intakeForms.length > 0 ? (
              <IntakeFormsTable intakeForms={intakeForms} />
            ) : (
              <InvalidOrderAlert />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const CopyLink = ({ url }) => {
  const [copied, setCopied] = useState(false)
  const [showCopyHover, setShowCopyHover] = useState(false)
  const popover = usePopoverState({
    modal: true,
    placement: 'bottom-end',
    gutter: 10,
  })

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  return (
    <>
      <PopoverDisclosure
        as={Button}
        {...popover}
        label="Share link"
        leadingIcon={ShareIcon}
        size="xs"
      />
      <Popover {...popover} aria-label="Share Intake form URL">
        <Card className="bg-white shadow-sm style-reset max-w-sm">
          <p className="text-sm text-gray-500 ">
            Share the link below with guests to allow them to fill out the intake form for this
            order.
          </p>

          <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
            <div
              className="relative mt-4 p-3 text-sm rounded-lg bg-gray-100 border border-gray-200 text-gray-900 !font-mono truncate cursor-pointer overflow-hidden"
              onMouseEnter={() => setShowCopyHover(true)}
              onMouseLeave={() => setShowCopyHover(false)}
            >
              {(showCopyHover || copied) && (
                <div className="inset-0 absolute bg-gray-200 flex items-center justify-center text-gray-900">
                  <div className="flex items-center space-x-1 font-bold">
                    <ClipboardCopyIcon className="w-4 h-4" />
                    <span>{copied ? 'Copied!' : 'Copy'}</span>
                  </div>
                </div>
              )}
              {url}
            </div>
          </CopyToClipboard>
        </Card>
      </Popover>
    </>
  )
}

const InvalidOrderAlert = () => {
  return (
    <Alert variant="warning" title="Missing intake form">
      There are no intake forms associated with this order.
    </Alert>
  )
}

const IntakeFormsTable = ({ intakeForms }) => {
  const can = useCurrentUserCan()

  if (!can(INTAKE_FORMS_VIEW)) {
    return null
  }

  return (
    <Table>
      <Table.THead>
        <Table.Tr>
          <Table.Th condensed>Status</Table.Th>
          <Table.Th condensed>Guest Name</Table.Th>
          <Table.Th condensed shrink>
            Completed at
          </Table.Th>
          <Table.Th condensed></Table.Th>
        </Table.Tr>
      </Table.THead>
      <Table.TBody>
        {intakeForms.map((form, i) => (
          <FormRow key={i} visitIntakeForm={form} />
        ))}
      </Table.TBody>
    </Table>
  )
}

const FormRow = ({ visitIntakeForm }) => {
  const {
    name: name,
    completed_at: completedAt = null,
    visit_intake_form_id: visitIntakeFormId = null,
  } = visitIntakeForm ?? {}

  return (
    <Table.Tr>
      <Table.Td condensed shrink>
        <CompletedTag complete={!!completedAt} />
      </Table.Td>
      <Table.Td condensed>{name}</Table.Td>
      <Table.Td condensed shrink>
        {completedAt && (
          <>{DateTime.fromJSDate(completedAt).toLocaleString(DateTime.DATETIME_MED)}</>
        )}
      </Table.Td>

      <Table.Td condensed className="text-right" shrink>
        {completedAt && (
          <>
            <ViewAnswersButton
              name={name}
              completedAt={completedAt}
              visitIntakeFormId={visitIntakeFormId}
              buttonComponent={({ onClick, isLoading }) => (
                <Button
                  loading={isLoading}
                  onClick={onClick}
                  label="View"
                  variant="ghost"
                  size="xs"
                />
              )}
            />
          </>
        )}
      </Table.Td>
    </Table.Tr>
  )
}

const NoPermissionAlert = () => {
  const can = useCurrentUserCan()

  if (can(INTAKE_FORMS_VIEW)) return null

  return (
    <div className="my-4">
      <Alert variant="warning" title="Missing permission">
        You do not have permission to view intake forms. Contact your Spa Manager.
      </Alert>
    </div>
  )
}

export const ViewAnswersButton = withUserHasPermission(
  ({ buttonComponent, visitIntakeFormId, name = null, completedAt }) => {
    const [html, setHtml] = useState(null)
    const notifications = useNotificationContext()
    const [currentWindow, setCurrentWindow] = useState(null)

    const { isLoading, refetch } = useVisitIntakeFormHtml(visitIntakeFormId, {
      enabled: false,
      retry: 0,
      onSuccess: (data) => {
        setHtml(data)
      },
      onError: (e) => notifications.notify(<ApiErrorNotification error={e} />),
    })

    const handleUnload = () => {
      setHtml(null)
    }

    const renderForm = useSudo(() => {
      // Use our visitIntakeFormId to get the rendered HTML from the API.
      setHtml(null)
      refetch()
    })

    return (
      <>
        {html && (
          <NewWindow
            onUnload={handleUnload}
            title="View intake form"
            features={{ width: 600, height: 800 }}
            ref={setCurrentWindow}
          >
            <div className="p-3 style-reset">
              <h1 className="text-xl text-center mb-5">
                <div className="text-xl font-semibold">Intake form</div>
                <div className="text-sm text-gray-500">
                  {name && `${name} · `}
                  {'Completed '}
                  {DateTime.fromJSDate(completedAt).toLocaleString({
                    weekday: 'long',
                    month: 'long',
                    day: '2-digit',
                  })}
                </div>
              </h1>

              <div className="pb-5 mb-5 flex space-x-2 w-full justify-center border-b border-gray-200">
                <div>
                  <Button
                    onClick={() => currentWindow.window.print()}
                    variant="default"
                    className="print:hidden"
                    leadingIcon={PrinterIcon}
                    label="Print"
                  />
                </div>
              </div>
            </div>

            <div dangerouslySetInnerHTML={{ __html: html }} />
          </NewWindow>
        )}

        {buttonComponent({
          isLoading,
          onClick: renderForm,
        })}
      </>
    )
  }
)

const CompletedTag = ({ complete }) => {
  const variant = complete ? 'success' : 'warning'
  const text = complete ? 'Complete' : 'Incomplete'

  return <Badge variant={variant} label={text} />
}
