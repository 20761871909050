import {
  ChangeEventHandler,
  ForwardedRef,
  KeyboardEventHandler,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Listbox, Portal, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Spinner } from '../Spinner'
import { createEvent } from 'utilities/createEvent'
import { usePopper } from 'react-popper'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { useTranslation } from 'react-i18next'

export interface SelectOption<T = string | number | null | boolean> {
  value: T
  name: string | JSX.Element
  altText?: string
  description?: string | JSX.Element
  disabled?: boolean
}

interface BaseSelectProps {
  name?: string
  options: SelectOption[]
  placeholder?: string | JSX.Element
  hideLabel?: boolean
  label?: string | JSX.Element
  disabled?: boolean
  loadMore?: () => void
  isLoadingMore?: boolean
  hasMore?: boolean
  baseKey?: string
  usePortal?: boolean
  filterValue?: string
  onFilterChange?: (value: string) => void
}

export interface SingleSelectProps extends BaseSelectProps {
  selected: SelectOption
  onChange: (value: SelectOption) => void
  multiple?: false
}

export interface MultiSelectProps extends BaseSelectProps {
  selected: SelectOption[]
  onChange: (value: SelectOption[]) => void
  multiple: true
}

export type SelectProps = SingleSelectProps | MultiSelectProps

export const Select: React.FC<SelectProps> = forwardRef(
  (props: SelectProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name = null,
      options = [],
      selected,
      onChange,
      hideLabel = false,
      label = null,
      disabled = false,
      loadMore = null,
      isLoadingMore = false,
      hasMore = false,
      multiple = false,
      baseKey = null,
      usePortal = true,
      filterValue = null,
      onFilterChange = null,
    } = props

    const { t } = useTranslation()
    const allowFiltering = !loadMore || onFilterChange
    const popperElRef = useRef(null)
    const filterRef = useRef(null)
    const [targetElement, setTargetElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const isParentResponsibleForFiltering = !!props.onFilterChange
    const [_filterValue, setFilterValue] = useState('')
    const [optionsToDisplay, setOptionsToDisplay] = useState(options)
    const { styles, attributes } = usePopper(targetElement, popperElement, {
      placement: 'bottom',
    })

    const handleFilterKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      // Stop propagation of the event if the user is typing in the filter.
      // This stops the default behaviour of filtering by the first character.
      // But still allow the user to use the arrow keys to navigate the list,
      // pressing Enter to select an option and pressing Escape to close the list.
      const allowedKeys = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape']

      if (allowedKeys.includes(e.key)) return

      e.stopPropagation()
    }

    const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      // If the parent is responsible for filtering, we'll just call the
      // parent's onFilterChange handler and let it handle the filtering.
      if (isParentResponsibleForFiltering) {
        onFilterChange(e.currentTarget.value)
        return
      }

      // Otherwise, we'll update our local state of the value and filter
      // the options ourselves.

      setFilterValue(e.currentTarget.value)

      const newFilters = options.filter((option) => {
        const nameToUse = option.altText ?? option.name.toString()
        return nameToUse.toLowerCase().includes(e.currentTarget.value.toLowerCase())
      })

      setOptionsToDisplay(newFilters)
    }

    const resetFilter = () => {
      setOptionsToDisplay(options)

      if (isParentResponsibleForFiltering) {
        onFilterChange('')
      } else {
        setFilterValue('')
      }
    }

    // We'll want to update the options to display whenever the options
    // prop changes.
    useEffect(() => {
      setOptionsToDisplay(options)
    }, [options])

    // When the list is expanded, focus the filter input (if it's there).
    useEffect(() => {
      if (filterRef?.current) {
        // Focus the input
        filterRef.current.focus()
        return
      }

      // The filter input isn't there, so clear the value or tell the
      // parent to clear it.
      resetFilter()
    }, [filterRef?.current])

    return (
      <Listbox
        value={multiple ? selected : selected?.value ?? null}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        by="value"
      >
        {({ open }) => (
          <>
            {!hideLabel && label && (
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                {label}
              </Listbox.Label>
            )}
            <div
              className={classNames({
                'mt-1': label && !hideLabel,
                relative: true,
              })}
            >
              <div ref={setTargetElement}>
                <Listbox.Button
                  name={name}
                  disabled={disabled}
                  className="bg-white dark:bg-gray-700 dark:border-transparent dark:text-white relative w-full border border-gray-300 rounded-lg shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-violet focus:border-gray-300 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {selected?.value === undefined && (
                    <span className="block truncate text-gray-500 dark:text-gray-400">
                      {t('select.placeholder')}
                    </span>
                  )}

                  <span className="block truncate">
                    {selected?.value !== undefined && selected?.name}
                  </span>
                  {selected?.description && (
                    <span className="text-xs block text-gray-500">{selected?.description}</span>
                  )}

                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
              </div>

              <ConditionalWrapper
                condition={usePortal}
                wrapper={(children) => <Portal>{children}</Portal>}
              >
                <div
                  ref={popperElRef}
                  style={{
                    ...styles.popper,
                    width: targetElement?.offsetWidth,
                  }}
                  className="style-reset z-20"
                  {...attributes.popper}
                >
                  <Transition
                    show={open}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => setPopperElement(null)}
                  >
                    <Listbox.Options
                      static
                      as="div"
                      className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-lg text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {allowFiltering && (
                        <div className="z-10 bg-white border-b border-gray-200 sticky top-0">
                          <input
                            ref={filterRef}
                            className="text-sm border-none w-full focus:outline-none focus:ring-0"
                            type="text"
                            placeholder={t('select.search.placeholder')}
                            onKeyDown={handleFilterKeyDown}
                            value={isParentResponsibleForFiltering ? filterValue : _filterValue}
                            onChange={handleFilterChange}
                          />
                        </div>
                      )}

                      <ul className="p-1">
                        {(allowFiltering ? optionsToDisplay : options).map((option) => (
                          <Listbox.Option
                            key={`${baseKey}_${JSON.stringify(option.value)}`}
                            disabled={option.disabled}
                            className={({ active, disabled }) =>
                              classNames(
                                active ? 'text-white bg-violet' : 'text-gray-900',
                                disabled ? 'bg-gray-100 text-gray-500 cursor-not-allowed' : '',
                                !active && !disabled ? 'text-gray-900 cursor-default' : '',
                                'select-none relative p-2 pl-3 pr-9 rounded-lg'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <div>
                                  <span
                                    className={classNames(
                                      selected ? 'font-medium' : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {option.name}
                                  </span>

                                  {option.description && (
                                    <span
                                      className={classNames(
                                        selected ? 'font-medium' : 'font-normal',
                                        'block text-xs',
                                        !active && !selected ? 'text-gray-500' : 'text-white'
                                      )}
                                    >
                                      {option.description}
                                    </span>
                                  )}
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-violet',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </ul>

                      {hasMore && (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            loadMore()
                          }}
                          disabled={isLoadingMore}
                          className="flex space-x-1 items-center justify-center w-full hover:text-white hover:bg-violet text-gray-700 cursor-default select-none relative p-2 pl-3 pr-9 rounded-lg"
                        >
                          {isLoadingMore && (
                            <>
                              <Spinner margin="0" />
                              <span>{t('select.search.loading_results')}</span>
                            </>
                          )}

                          {!isLoadingMore && (
                            <>
                              <ChevronDownIcon className="w-5 h-5" />
                              <span>{t('select.search.load_more')}</span>
                            </>
                          )}
                        </button>
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </ConditionalWrapper>
            </div>
          </>
        )}
      </Listbox>
    )
  }
)

export const FormikSelect = forwardRef<any, any>(({ onChange: parentOnChange, ...props }, ref) => {
  const handleOnChange = (value) => {
    parentOnChange(createEvent({ value: value.value ?? '', name: props.name }))
  }

  if (props.value !== undefined) {
    props.selected = props.options.find((option) => option.value === props.value)
  }

  return <Select onChange={handleOnChange} ref={ref} {...props} />
})
