import { CashIcon, LinkIcon } from '@heroicons/react/solid'
import { useCustomerMemberships } from 'api/CustomerMemberships'
import { useParams } from 'react-router-dom'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { DateTime, SystemZone } from 'luxon'
import { Badge } from 'ui/components/Badge'
import { useState } from 'react'
import { CreateMembershipModal } from './CreateMembershipModal'
import { DescriptionItem } from 'ui/components/DescriptionItem'
import { EmptyState } from 'ui/components/EmptyState'
import {
  ChevronRightIcon,
  ExclamationIcon,
  IdentificationIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import {
  CheckIcon,
  DotsHorizontalIcon,
  EyeIcon,
  DocumentDownloadIcon,
  ReceiptRefundIcon,
} from '@heroicons/react/solid'
import { useGetCustomer } from 'api/Customers'
import { useChargesForMembership, useDownloadChargeReceipt } from 'api/MembershipCharges'
import { Money } from 'ui/components/Money'
import { useMemo } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { ApiErrorNotification, useNotificationContext } from 'ui/components/Notification'
import { EditMembershipModal } from './EditMembershipModal'
import { Section } from '../Shared/Section'
import { useUserHasTrybeEmail } from 'hooks/UseUser'
import { MembershipDropdown } from './MembershipDropdown'
import { PaymentModal } from './PaymentModal'
import { RequestMandateModal } from './RequestMandateModal'
import { CancelModal } from './CancelModal'
import { ArchiveModal } from './ArchiveModal'
import { PaymentMethodIcon } from 'ui/components/PaymentMethodIcon'
import { RetryPaymentModal } from './RetryPaymentModal'
import { ManageMembersModal } from './ManageMembersModal'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useCanRecordManualPayment } from 'components/MembershipList/MembershipRow'
import { ChargePaymentModal } from './ChargePaymentModal'
import { PaginatedMiniList } from 'ui/components/PaginatedMiniList'
import { ChargeSummaryStatusEnum, MembershipCharge } from '@trybeapp/sdk'
import { RefundModal } from './RefundModal'
import { MembershipAuditLog } from './MembershipAuditLog'
import { sdkDateToSystemDateTime } from 'utilities/DateUtils/dateUtils'

export const useBillingPeriod = (charge) => {
  let billingPeriod

  if (charge?.billing_period_from) {
    // Do the from and to in cover the whole month? if so, just output the month and year.
    const from = sdkDateToSystemDateTime(charge.billing_period_from)
    const to = sdkDateToSystemDateTime(charge.billing_period_to)
    const { values } = to.diff(from, 'months')

    if (
      values.months < 1 &&
      from.day === 1 &&
      from.plus({ month: 1 }).minus({ day: 1 }).equals(to)
    ) {
      billingPeriod = `${from.toLocaleString({
        month: 'long',
        year: 'numeric',
      })}` // Formats like "September 2023"
    } else if (from.year === to.year) {
      // Only include the year in the 'to' date if it's the same year,
      // e.g. "27 Sept 2023 - 30 Sept 2023"
      billingPeriod = `${from.toLocaleString({
        day: 'numeric',
        month: 'short',
      })} - ${to.toLocaleString({
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}`
    } else {
      billingPeriod = `${from.toLocaleString(DateTime.DATE_MED)} - ${to.toLocaleString(
        DateTime.DATE_MED
      )}`
    }
  }

  return billingPeriod
}

export const DescriptionForCharge = ({ charge }) => {
  const billingPeriod = useBillingPeriod(charge)

  if (charge.processor === 'manual') {
    return (
      <div>
        Manual payment for {billingPeriod ? billingPeriod : 'membership'} (
        {charge.processor_data.processor_type})
      </div>
    )
  }

  return <div>Auto payment for {billingPeriod ? billingPeriod : 'membership'}</div>
}

export const MembershipChargeStatusBadge = ({ charge }) => {
  const status = useMemo(() => {
    switch (true) {
      case charge.status === 'pending':
        return 'Upcoming'
      case charge.amount_refunded > 0 && charge.amount_refunded < charge.amount:
        return 'Part. refunded'
      case charge.amount_refunded > 0 && charge.amount_refunded === charge.amount:
        return 'Refunded'
      case charge.status === 'succeeded':
        return 'Succeeded'
      case charge.status === 'processing':
        return 'Processing'
      case charge.status === 'awaiting_approval':
        return 'Preparing'
      case charge.status === 'requires_payment_method':
      case charge.status === 'canceled':
      case charge.status === 'failed':
        return 'Failed'
      default:
        return charge.status
    }
  }, [charge.status, charge.amount_refunded])

  const statusVariant = useMemo(() => {
    switch (true) {
      case charge.amount_refunded > 0:
        return 'warning'
      case charge.status === 'succeeded':
        return 'success'
      case charge.status === 'requires_payment_method':
      case charge.status === 'canceled':
      case charge.status === 'failed':
        return 'danger'
      case charge.status === 'pending':
      case charge.status === 'processing':
      case charge.status === 'awaiting_approval':
        return 'info'
      default:
        return null
    }
  }, [charge.status, charge.amount_refunded])

  return <Badge variant={statusVariant} label={status} />
}

interface ChargeRowProps {
  charge: MembershipCharge
  onRefund: () => void
  onDownloadReceipt: () => Promise<void>
  canRecordManualPayment: boolean
  onRecordPayment: () => void
  isLead: boolean
}

const ChargeRow: React.FC<ChargeRowProps> = ({
  charge,
  onRefund,
  onDownloadReceipt,
  canRecordManualPayment,
  onRecordPayment,
  isLead,
}) => {
  const [downloading, setDownloading] = useState(false)
  const featureFlags = useActiveFeatureFlags()

  const handleDownload = async () => {
    setDownloading(true)

    try {
      await onDownloadReceipt()
    } catch (_) {
      // Error is handled in parent
    } finally {
      setDownloading(false)
    }
  }

  return (
    <div className="flex w-full">
      <div className="pl-4 px-2 py-3 font-medium text-right w-20">
        <Money currency={charge.currency} amount={charge.amount} />
      </div>
      <div className="whitespace-nowrap px-2 py-3 pt-3 w-28">
        <MembershipChargeStatusBadge charge={charge} />
      </div>
      <div className="px-2 py-3 flex-1">
        <DescriptionForCharge charge={charge} />
        <div className="text-gray-500 text-xs">{charge.description}</div>
      </div>
      <div className="whitespace-nowrap px-2 py-3 text-gray-500 text-right">
        {DateTime.fromJSDate(charge.processing_at ?? charge.created_at).toLocaleString(
          DateTime.DATE_MED
        )}
      </div>
      <div className="pr-4 px-2 py-2">
        <Dropdown
          buttonVariant="ghost"
          buttonLeadingIcon={DotsHorizontalIcon}
          buttonSize="xs"
          buttonChevronHidden
        >
          {canRecordManualPayment &&
            isLead &&
            featureFlags.includes('membership_revenue_schedule') &&
            ![ChargeSummaryStatusEnum.Processing, ChargeSummaryStatusEnum.Succeeded].includes(
              charge.status as ChargeSummaryStatusEnum
            ) && <DropdownItem onClick={onRecordPayment} label="Record payment" icon={CashIcon} />}
          <DropdownItem
            label={`${downloading ? 'Downloading...' : 'Download receipt'}`}
            disabled={charge.status !== 'succeeded' || downloading}
            subtitle={charge.status !== 'succeeded' ? "Receipt hasn't been generated yet" : ''}
            onClick={handleDownload}
            icon={DocumentDownloadIcon}
          />
          <DropdownItem
            label="Refund"
            subtitle={
              charge.status !== 'succeeded'
                ? 'You can only refund successful charges'
                : charge.amount_refunded === charge.amount
                ? 'Already refunded'
                : ''
            }
            disabled={charge.status !== 'succeeded' || charge.amount_refunded === charge.amount}
            onClick={onRefund}
            icon={ReceiptRefundIcon}
          />

          {useUserHasTrybeEmail() && charge.status !== 'awaiting_approval' && charge.stripe_id && (
            <DropdownItem
              label="View in Stripe"
              subtitle="You're seeing this because you're an @try.be user"
              href={`https://dashboard.stripe.com/payments/${charge.stripe_id}`}
              icon={EyeIcon}
            />
          )}
        </Dropdown>
      </div>
    </div>
  )
}

const Charges = ({ membershipId, canRecordManualPayment, isLead, includePending }) => {
  const [recordPaymentForCharge, setRecordPaymentForCharge] = useState(null)
  const [page, setPage] = useState(1)
  const [showingRefundConfirmation, setShowingRefundConfirmation] = useState(null)
  const notifications = useNotificationContext()

  const { isLoading, isError, data } = useChargesForMembership(membershipId, {
    page,
    status: includePending ? 'all' : undefined,
    perPage: 5,
  })

  const charges = data?.data ?? []
  const meta = data?.meta

  const { mutateAsync: generateReceiptAsync } = useDownloadChargeReceipt()

  const handleOnDownloadReceipt = async (charge: MembershipCharge) => {
    await generateReceiptAsync(charge.id, {
      onSuccess: (resp: any) => {
        const elm = document.createElement('a')
        elm.setAttribute('href', resp.data.download_url)
        elm.style.display = 'none'
        document.body.appendChild(elm)
        elm.click()
        document.body.removeChild(elm)
      },
      onError: (e: any) => {
        notifications.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <>
      <PaginatedMiniList
        isLoading={isLoading}
        isError={isError}
        emptyMessage="No charges found"
        meta={meta}
        rows={charges.map((charge) => (
          <ChargeRow
            key={charge.id}
            charge={charge}
            onRefund={() => setShowingRefundConfirmation(charge)}
            onDownloadReceipt={() => handleOnDownloadReceipt(charge)}
            canRecordManualPayment={canRecordManualPayment}
            onRecordPayment={() => setRecordPaymentForCharge(charge)}
            isLead={isLead}
          />
        ))}
        onPageChange={setPage}
      />
      <ChargePaymentModal
        isOpen={recordPaymentForCharge !== null}
        charge={recordPaymentForCharge}
        onClose={() => setRecordPaymentForCharge(null)}
      />

      <RefundModal
        isOpen={showingRefundConfirmation !== null}
        charge={showingRefundConfirmation}
        onClose={() => setShowingRefundConfirmation(null)}
      />
    </>
  )
}

export const MembershipBadge = ({ membership }) => {
  switch (membership.status) {
    case 'active':
      return <Badge variant="success" label="Active" />
    case 'needs_dd_mandate':
    case 'needs_attention':
      return <Badge variant="warning" label="Needs attention" />
    case 'inactive':
      return <Badge variant="danger" label="Cancelled" />
    case 'expired':
      return <Badge variant="danger" label="Expired" />
    case 'upcoming':
      return <Badge variant="info" label="Upcoming" />
    default:
      return <Badge variant="info" label={membership.status} />
  }
}

export const MembershipStatusDetail = ({ membership }) => {
  const attentionReason = useMemo(() => {
    switch (membership.attention_reason) {
      case 'no_mandate':
        return 'No mandate has been provided'
      case 'mandate_revoked':
        return 'Mandate has been revoked'
      case 'payment_failed':
        return 'Payment failed'
      case 'payment_disputed':
        return 'A payment was disputed'
      case 'payment_outstanding':
        return 'Manual payment outstanding'
      case 'setup_unpaid':
        return 'Setup fee is unpaid'
      default:
        return membership.attention_reason
    }
  }, [membership.attention_reason])

  return (
    <>
      <div
        className={`font-medium ${
          membership.status === 'needs_attention'
            ? membership.attention_reason === 'payment_disputed'
              ? 'text-red-500'
              : 'text-yellow-500'
            : membership.status === 'expired'
            ? 'text-red-500'
            : membership.status === 'upcoming'
            ? 'text-blue-500'
            : 'text-green-500'
        }`}
      >
        {membership.status === 'active' && (
          <>
            <CheckIcon className="w-5 h-5 inline mr-1" />
            No action required
          </>
        )}

        {membership.status === 'needs_attention' && (
          <>
            <ExclamationIcon className="w-5 h-5 inline mr-1" />
            {attentionReason}
          </>
        )}

        {membership.status === 'upcoming' && (
          <>
            <InformationCircleIcon className="w-5 h-5 inline mr-1" />
            {`Starts ${DateTime.fromJSDate(membership.start_date).toLocaleString(
              DateTime.DATE_MED
            )}`}
          </>
        )}

        {membership.status === 'expired' && (
          <>
            <ExclamationIcon className="w-5 h-5 inline mr-1" />
            Expired {DateTime.fromJSDate(membership.end_date).toLocaleString(DateTime.DATE_MED)}
          </>
        )}
      </div>
      {membership.next_billing_date && (
        <div className="text-gray-500">
          Next billing date{' '}
          {DateTime.fromJSDate(membership.next_billing_date, {
            zone: new SystemZone(),
          }).toLocaleString(DateTime.DATE_MED)}
        </div>
      )}
    </>
  )
}

export const MembershipRateDescriptor = ({ membership }) => {
  const duration = useMemo(() => {
    switch (membership.rate.billing_frequency) {
      case 'P1M':
        return 'month'
      case 'P3M':
        return 'quarter'
      case 'P6M':
        return 'half-year'
      case 'P1Y':
        return 'year'
    }
  }, [membership.rate.billing_frequency])

  return (
    <div className="ml-1 text-gray-500">
      {membership.rate.name} at{' '}
      <Money currency={membership.rate.currency} amount={membership.rate.price} />
      {` per ${duration}`}
      {membership.type.offline_payments && (
        <span className="italic text-xs"> &middot; Manual payments</span>
      )}
    </div>
  )
}

export const MembershipRow = ({ isLead, membership, withStatusDetail = true, onViewMembers }) => {
  return (
    <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-4 justify-between items-center flex-1">
      <div className="space-y-1">
        <div className="flex space-x-1 items-center">
          <MembershipBadge membership={membership} />
          <span className="font-medium">{membership.type.name}</span>
        </div>
        <MembershipRateDescriptor membership={membership} />

        {membership.type.max_members > 1 && (
          <div className="mx-1 text-xs flex items-center space-x-1 text-gray-500">
            <span>{isLead ? 'Lead member' : `Shared with ${membership.customer.full_name}`}</span>
            <span>&middot;</span>
            <button className="font-medium text-violet hover:underline" onClick={onViewMembers}>
              View members
            </button>
          </div>
        )}
      </div>

      {withStatusDetail && (
        <div className="text-left sm:text-right">
          <MembershipStatusDetail membership={membership} />
        </div>
      )}
    </div>
  )
}

export const Membership = ({
  isLead,
  membershipNumber,
  membership,
  onCancel,
  onEdit,
  onRequestMandate,
  onAddPayment,
  onArchive,
  onRetryPayment,
  onManageMembers,
  onOpenAuditLog,
}) => {
  const canRecordManualPayment = useCanRecordManualPayment(membership)

  return (
    <Disclosure as="li" className="block w-full">
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            className={`text-left w-full appearance-none flex justify-between items-center p-3 space-x-4 hover:bg-violet-50 ${
              open ? 'bg-violet-50' : ''
            }`}
          >
            <ChevronRightIcon
              className={`w-6 h-6 text-gray-400 transition transform ${open ? 'rotate-90' : ''}`}
            />

            <MembershipRow
              isLead={isLead}
              membership={membership}
              onViewMembers={onManageMembers}
            />
            <div className="flex-0 flex space-x-2 items-center">
              <MembershipDropdown
                isLead={isLead}
                membership={membership}
                onCancel={onCancel}
                onEdit={onEdit}
                onRequestMandate={onRequestMandate}
                onAddPayment={onAddPayment}
                onArchive={onArchive}
                onRetryPayment={onRetryPayment}
                onManageMembers={onManageMembers}
                canRecordManualPayment={canRecordManualPayment}
                onOpenAuditLog={onOpenAuditLog}
              />
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform -translate-y-10 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform -translate-y-5 opacity-0"
          >
            <Disclosure.Panel static className="bg-violet-50 p-3">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 gap-y-6">
                <div>
                  <DescriptionItem
                    label={`Membership ${
                      membership.start_date > DateTime.now() ? 'starts' : 'started'
                    }`}
                  >
                    {DateTime.fromJSDate(membership.start_date, {
                      zone: new SystemZone(),
                    }).toLocaleString(DateTime.DATE_MED)}
                  </DescriptionItem>
                </div>
                <div>
                  <DescriptionItem
                    label={`Membership ${
                      membership.end_date && membership.end_date < DateTime.now() ? 'ended' : 'ends'
                    }`}
                  >
                    {membership.end_date &&
                      DateTime.fromJSDate(membership.end_date, {
                        zone: new SystemZone(),
                      }).toLocaleString(DateTime.DATE_MED)}

                    {!membership.end_date && <span className="italic">N/A</span>}
                  </DescriptionItem>
                </div>
                <div>
                  <DescriptionItem label="Next billing date">
                    {membership.next_billing_date &&
                      DateTime.fromJSDate(membership.next_billing_date, {
                        zone: new SystemZone(),
                      }).toLocaleString(DateTime.DATE_MED)}

                    {!membership.next_billing_date && <span className="italic">N/A</span>}
                  </DescriptionItem>
                </div>
                <div>
                  <DescriptionItem label="Membership number">{membershipNumber}</DescriptionItem>
                </div>
                <div>
                  <DescriptionItem label="Payment method">
                    {membership.payment_method && (
                      <div className="flex space-x-2 items-center">
                        <PaymentMethodIcon
                          size={32}
                          type={
                            membership.payment_method.type === 'card'
                              ? membership.payment_method.card_brand
                              : membership.payment_method.type
                          }
                        />
                        <div>
                          {membership.payment_method.type === 'card' && Array(12).fill(<>&bull;</>)}
                          {membership.payment_method.type === 'direct_debit' &&
                            Array(4).fill(<>&bull;</>)}
                          {membership.payment_method.last_4}
                        </div>
                      </div>
                    )}

                    {!membership.payment_method && <span className="italic">N/A</span>}
                  </DescriptionItem>
                </div>
                <div>
                  <DescriptionItem label="Source">
                    {membership.source === 'app' && 'Created in app'}
                    {membership.source === 'self_signup' && 'Self-signup'}
                    {membership.source === 'import' && 'Imported'}
                    {!membership.source && <span className="italic">Unknown</span>}
                  </DescriptionItem>
                </div>
                {membership.basket_id && (
                  <div>
                    <DescriptionItem label="Associated order">
                      <a
                        href={`/orders/${membership.basket_id}`}
                        className="flex space-x-1 items-center font-medium text-violet hover:text-blue-700"
                      >
                        <LinkIcon className="w-5 h-5" />
                        <span>Go to order</span>
                      </a>
                    </DescriptionItem>
                  </div>
                )}
                {membership.external_ref && (
                  <div>
                    <DescriptionItem label="External ref">
                      {membership.external_ref}
                    </DescriptionItem>
                  </div>
                )}
              </div>

              <div className="mt-6">
                <DescriptionItem label="Charges">
                  <Charges
                    membershipId={membership.id}
                    canRecordManualPayment={canRecordManualPayment}
                    isLead={isLead}
                    includePending={!!membership.type?.revenue_schedule}
                  />
                </DescriptionItem>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export const Memberships = () => {
  const { clientId } = useParams()
  const [showingCancelConfirmation, setShowingCancelConfirmation] = useState(null)
  const [showingMandateRequestConfirmation, setShowingMandateRequestConfirmation] = useState(null)
  const [showingEditModal, setShowingEditModal] = useState(null)
  const [showingRecordPaymentModal, setShowingRecordPaymentModal] = useState(null)
  const [showingRetryPaymentModal, setShowingRetryPaymentModal] = useState(null)
  const [showingArchiveConfirmation, setShowingArchiveConfirmation] = useState(null)
  const [managingMembers, setManagingMembers] = useState(null)
  const { isLoading, data: { data: memberships = [] } = {} } = useCustomerMemberships(clientId)
  const { isLoading: isLoadingClient, data: { data: client = {} } = {} } = useGetCustomer(clientId)
  const [membershipAuditLog, setMembershipAuditLog] = useState(null)

  return (
    <Section
      title="Memberships"
      button={
        !isLoadingClient &&
        client.deleted_at === null && <CreateMembershipModal clientId={client.id} />
      }
    >
      {isLoading && <div className="bg-grape-100 animate-pulse h-16" />}

      {!isLoading && memberships.length === 0 && (
        <EmptyState title="This client has no memberships" icon={IdentificationIcon} />
      )}

      {!isLoading && memberships.length > 0 && (
        <ul className="text-sm divide-y divide-violet-100">
          {memberships.map((membership) => (
            <Membership
              key={membership.id}
              membershipNumber={
                membership.members.find((m) => m.customer_id === clientId).membership_number
              }
              isLead={membership.members.find((m) => m.customer_id === clientId).is_lead}
              membership={membership}
              onCancel={(id) => setShowingCancelConfirmation(id)}
              onEdit={(id) => setShowingEditModal(id)}
              onRequestMandate={(id) => setShowingMandateRequestConfirmation(id)}
              onAddPayment={() => setShowingRecordPaymentModal(membership)}
              onArchive={(id) => setShowingArchiveConfirmation(id)}
              onRetryPayment={() => setShowingRetryPaymentModal(membership)}
              onManageMembers={() => setManagingMembers(membership)}
              onOpenAuditLog={() => setMembershipAuditLog(membership)}
            />
          ))}
        </ul>
      )}

      <MembershipAuditLog
        isOpen={membershipAuditLog !== null}
        membershipId={membershipAuditLog?.id}
        onClose={() => setMembershipAuditLog(null)}
      />

      <PaymentModal
        isOpen={showingRecordPaymentModal !== null}
        membership={showingRecordPaymentModal}
        onClose={() => setShowingRecordPaymentModal(null)}
      />

      <EditMembershipModal
        membershipId={showingEditModal}
        onClose={() => setShowingEditModal(null)}
      />

      <RequestMandateModal
        isOpen={!!showingMandateRequestConfirmation}
        membershipId={showingMandateRequestConfirmation}
        onClose={() => setShowingMandateRequestConfirmation(null)}
      />

      <CancelModal
        isOpen={!!showingCancelConfirmation}
        onClose={() => setShowingCancelConfirmation(null)}
        membershipId={showingCancelConfirmation}
      />

      <ArchiveModal
        isOpen={!!showingArchiveConfirmation}
        onClose={() => setShowingArchiveConfirmation(null)}
        membershipId={showingArchiveConfirmation}
      />

      <RetryPaymentModal
        isOpen={!!showingRetryPaymentModal}
        membership={showingRetryPaymentModal}
        onClose={() => setShowingRetryPaymentModal(null)}
      />

      <ManageMembersModal
        isOpen={!!managingMembers}
        onClose={() => setManagingMembers(null)}
        membership={managingMembers}
      />
    </Section>
  )
}
