import {
  CalendarIcon,
  ChartBarIcon,
  CogIcon,
  CubeIcon,
  HomeIcon,
  MapIcon,
  ShoppingBagIcon,
  UsersIcon,
} from '@heroicons/react/outline'
import { useActiveFeatureFlags, useCurrentSite } from 'contexts/SiteConfig'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'
import { chain } from 'lodash'
import { useMemo } from 'react'
import {
  CUSTOMERS_VIEW,
  INVENTORY_VIEW,
  REPORTS_VIEW,
  RESERVATIONS_VIEW,
  SETTINGS_VIEW,
} from 'constants/permissions'

export const useSidebarLinks = () => {
  const can = useCurrentUserCan()
  const featureFlags = useActiveFeatureFlags()
  const { appointment_enquiries_enabled: enquiriesEnabled } = useCurrentSite()

  const links = [
    {
      to: '/',
      heroIcon: HomeIcon,
      title: 'Home',
      exact: true,
    },
    {
      to: featureFlags.includes('new_orders_list') ? '/beta/orders' : '/orders',
      heroIcon: ShoppingBagIcon,
      title: 'Orders',
      permissions: [RESERVATIONS_VIEW],
      childLinks: [
        {
          to: featureFlags.includes('new_orders_list') ? '/beta/orders' : '/orders',
          title: 'All orders',
          exact: true,
        },
        enquiriesEnabled && {
          to: '/orders/enquiries',
          title: 'Enquiries',
        },
        {
          to: '/dashboards/pay-by-link',
          title: 'Pay by link',
        },
        {
          to: '/vouchers',
          title: 'Vouchers',
        },
      ].filter(Boolean),
    },
    {
      to: '/visits',
      heroIcon: MapIcon,
      title: 'Visits',
      permissions: [RESERVATIONS_VIEW],
    },
    {
      to: '/calendar',
      heroIcon: CalendarIcon,
      title: 'Calendar',
      permissions: [RESERVATIONS_VIEW],
      childLinks: [
        {
          to: '/calendar',
          title: 'Diary',
          exact: true,
        },
        {
          to: '/calendar/schedule',
          title: 'Schedule',
        },
        {
          to: '/practitioner-availability',
          title: 'Rota',
        },
      ],
    },
    {
      to: featureFlags.includes('new_client_list') ? '/beta/clients' : '/clients',
      heroIcon: UsersIcon,
      title: 'Clients',
      permissions: [CUSTOMERS_VIEW],
      childLinks: [
        {
          to: featureFlags.includes('new_client_list') ? '/beta/clients' : '/clients',
          title: 'Client profiles',
        },
        {
          to: '/memberships',
          title: 'Memberships',
        },
        featureFlags.includes('marketing') && {
          to: '/emails',
          title: 'Emails',
          isBeta: true,
        },
      ].filter(Boolean),
    },
    {
      to: '/inventory/stock-items',
      heroIcon: CubeIcon,
      title: 'Inventory',
      permissions: [INVENTORY_VIEW],
      childLinks: [
        {
          to: '/inventory/stock-items',
          title: 'Stock items',
        },
        {
          to: '/inventory/stock-orders',
          title: 'Stock orders',
        },
        {
          to: '/inventory/stock-takes',
          title: 'Stocktakes',
        },
      ],
    },
    {
      to: '/reports',
      heroIcon: ChartBarIcon,
      title: 'Reports',
      permissions: [REPORTS_VIEW],
    },
    {
      to: '/settings',
      title: 'Settings',
      permissions: [SETTINGS_VIEW],
      heroIcon: CogIcon,
    },
  ].filter(Boolean)

  return useMemo(
    () =>
      links.filter(
        ({ permissions = [], featureFlag = null, visible = true }) =>
          visible !== false &&
          (!featureFlag || featureFlags.includes(featureFlag)) &&
          chain(permissions)
            .every((permission) => can(permission))
            .value()
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [can, JSON.stringify(featureFlags)]
  )
}
