import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../lang/en.json'
import Pseudo from 'i18next-pseudo'

// the translations
const resources = {
  en: {
    translation: en,
  },
}

// The environment variable gets sent to us as a string.
const isEnabledInEnvironment = import.meta.env.VITE_APP_ENABLE_PSUEDOLOCALISATION === 'true'

i18n
  .use(
    new Pseudo({
      enabled: import.meta.env.DEV && isEnabledInEnvironment === true,
    })
  )
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    postProcess: ['pseudo'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
