import { all } from 'redux-saga/effects'
import { OrdersSaga, OrderEmailsSaga, OrderLabelsSaga } from '@trybeapp/ducks.orders'
import { PractitionerScheduledAvailabilitySaga } from '@trybeapp/ducks.practitioner-scheduled-availability'
import { PackagesSaga, PackageItemChoicesSaga } from '@trybeapp/ducks.packages'

export default function* rootSaga() {
  yield all([
    OrderEmailsSaga(),
    OrderLabelsSaga(),
    OrdersSaga(),
    PackageItemChoicesSaga(),
    PackagesSaga(),
    PractitionerScheduledAvailabilitySaga(),
  ])
}
