import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { STATUS_CANCELLED, STATUS_RESERVED, STATUS_CONFIRMED } from 'constants/orderStatuses'
import { Card } from '../../../components/Card'
import { useDeleteOrderItem } from 'api/OrderItems'
import { useOrderIsLocked, useOrderIsSettled } from '../hooks'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon, CheckIcon, LinkIcon } from '@heroicons/react/outline'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { StatusTag } from '../Overview'
import { formatMoney } from 'ui/components/Money'
import { ItemActions } from './ItemActions'

export const HotelRoomReservationItemRow = ({ item }) => {
  const { orderId } = useParams()
  const { id: itemId, status: itemStatus, type_name: name = '', type_product_code: code } = item
  const itemIsCancelled = itemStatus === STATUS_CANCELLED
  const itemIsConfirmed = itemStatus === STATUS_CONFIRMED
  const mewIsProcessor = item.item_configuration.processor === 'mews'
  const hasPropertyId = item.item_configuration.property_id !== null
  const canEdit =
    !itemIsCancelled && !itemIsConfirmed && !useOrderIsSettled() && !useOrderIsLocked()
  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Card className="my-2">
      <Card.Body>
        <div className="text-nude-700 text-sm font-bold mb-2">
          {name} {code && <>[{code}]</>}
        </div>
        <table className="table-fixed">
          <thead>
            <tr className="text-nude-400 uppercase font-semibold text-xs tracking-wider text-left">
              <th className="w-[13%]">Status</th>
              <th className="w-[29%]">Check in and departure times</th>
              <th className="w-[31%]">Guest count</th>
              <th className="w-[31%]">Rate</th>
              <th className="w-[27%]">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-nude-900">
              <td>
                <Status item={item} />
              </td>
              <td>
                <Times item={item} />
              </td>
              <td>{item.item_configuration.guest_count}</td>
              <td>{item.item_configuration.rate_name}</td>
              <td>{formatMoney({ amount: item.total_cost, currency: item.currency })}</td>
              <td className="text-right">
                {itemIsConfirmed ? (
                  mewIsProcessor &&
                  hasPropertyId && (
                    <div>
                      <Dropdown threeDots>
                        <ViewInMewsDropdownOption item={item} />
                      </Dropdown>
                    </div>
                  )
                ) : (
                  <div>
                    <ItemActions
                      item={item}
                      handleDelete={handleDelete}
                      isDeleting={isDeleting}
                      canChangePrice={false}
                    >
                      {canEdit && <EditItemDropdownOption item={item} />}
                      {canEdit && <ConfirmItemDropdownOption item={item} />}
                    </ItemActions>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card.Body>
    </Card>
  )
}

export const Status = ({ item }) => {
  const { status: itemStatus } = item
  let displayStatus

  switch (itemStatus) {
    case STATUS_RESERVED:
      displayStatus = 'Pending'
      break

    case 'received':
      displayStatus = 'Received'
      break

    default:
      displayStatus = itemStatus
  }

  return <StatusTag status={displayStatus} />
}

const Times = ({ item }) => {
  const { item_configuration: purchasableDetails } = item
  const timeFrom = DateTime.fromISO(purchasableDetails.check_in_date).toLocaleString(
    DateTime.DATE_SHORT
  )
  const timeTo = DateTime.fromISO(purchasableDetails.check_out_date).toLocaleString(
    DateTime.DATE_SHORT
  )
  return (
    <>
      {timeFrom} - {timeTo}
    </>
  )
}

const EditItemDropdownOption = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleOnClick = () => {
    setValue('appointmentEnquiryWizard.editingEnquiry', item)
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}

const ConfirmItemDropdownOption = ({ item }) => {
  const { updateValues } = useOrderDetails()

  const handleOnClick = () => {
    updateValues({
      'appointmentEnquiryWizard.editingEnquiry': item,
      'appointmentEnquiryWizard.activeStep': 1,
    })
  }

  return <DropdownItem label="Confirm" onClick={handleOnClick} icon={CheckIcon} />
}

const ViewInMewsDropdownOption = ({ item }) => {
  const { item_configuration: purchasableDetails } = item
  const mewsPath =
    import.meta.env.VITE_APP_PROJECT_ENVIRONMENT === 'production'
      ? 'https://app.mews.com'
      : 'https://app.mews-demo.com'
  const propertyId = purchasableDetails.property_id
  const externalReservationId = purchasableDetails.external_reservation_id
  const externalUrl = `${mewsPath}/Commander/${propertyId}/Reservation/Detail/${externalReservationId}/Status`

  return (
    <DropdownItem
      label="View in Mews"
      icon={LinkIcon}
      href={externalUrl}
      target="_blank"
      rel="noopener noreferrer"
    />
  )
}
